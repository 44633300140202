import React, { useState, useMemo, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import './OfficialPC.scss';
import LogoHeader from '../../images/new_logo.png';
import EnLogoHeader from '../../images/en_logo_header.png';
import lookLogo from '../../images/ilookcap_logo_121x75.png';
import largeLooklogo from '../../images/ilookcap_logo_200x133.png';
import header_title from '../../images/H1_header.png';
import header_title_en_mini from '../../images/en-banner.png';
import DownloadPC from '../download/downloadPC.jsx';
import { getBaikeUrl } from '../../utils/constants.js';
import misc from '../../services/misc';
import Advantage from './component/advantage';
import About from './component/about';
import Bank from './component/bank';
import Contact from './component/contact';
import TeamLeader from './component/TeamLeader';
import Support from './component/support';
import Assistant from './component/assistant';
import Aum from './component/aum';
import Technology from './component/technology';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const numMap = {
    'top-div': '首页',
    'baike-link': '财富百科',
    team: '团队成员',
    contact: '联系我们',
    download: '下载APP',
    business: '鹿可业务',
    about: '关于鹿可',
    'top-div-img': '鹿可logo',
};
let availHeight = window.screen.availHeight - 90;

const symbols = [
    {
        proName: 'FOREXCOM:SPXUSD',
        title: 'S&P 500',
    },
    {
        description: '恒生指数',
        proName: 'FXOPEN:HSI',
    },
    {
        description: '恒生科技指数',
        proName: 'HSI:HSTECH',
    },
    {
        description: '道琼斯指数',
        proName: 'DJ:DJI',
    },
    {
        description: '沪深 300',
        proName: 'SSE:000300',
    },
    {
        description: '中概互联网指数',
        proName: 'AMEX:KWEB',
    },
    {
        description: 'HXC 金龙指数',
        proName: 'NASDAQ:HXC',
    },
    {
        description: 'NDX 纳斯达克 100',
        proName: 'NASDAQ:NDX',
    },
    {
        description: 'SOX 半导体指数',
        proName: 'NASDAQ:SOX',
    },
    {
        description: 'XLP 日常消费品指数',
        proName: 'AMEX:XLP',
    },
    {
        description: 'XLV 医疗行业指数',
        proName: 'AMEX:XLV',
    },
    {
        description: 'BTC',
        proName: 'COINBASE:BTCUSD',
    },
    {
        description: 'ETH',
        proName: 'COINBASE:ETHUSD',
    },
];

export default function OfficialPC() {
    const { t, i18n } = useTranslation();
    const [navIsTop, setNavIsTop] = useState(false);
    const [selectId, setSelectId] = useState('top-div');
    const [showReturn, setShowReturn] = useState(false);
    const lastEventRef = useRef(true);
    const hasLoadBaike = useRef(false);
    const rectTops = useRef([]);
    const [currentKey, setCurrentKey] = useState(0);
    const widthType = useSelector(state => state.windowSizeReducer.mobileType);
    const isEN = i18n.language === 'en';
    const isZH = i18n.language === 'zh';
    //函数定义
    const scrollToAnchor = prop => {
        let { times, anchorName } = prop;

        anchorName = anchorName === 'top-div-img' ? 'top-div' : anchorName;
        if (anchorName === undefined) {
            anchorName = prop;
        }
        if (times === undefined && anchorName !== 'top-div-return') {
            misc.eventReport([
                {
                    event_type: 2,
                    event_id: 30004,
                    params: { title: numMap[anchorName] },
                    ts: +new Date(),
                    sid: '',
                },
            ]);
        }
        if (anchorName === 'top-div-return') {
            misc.eventReport([
                {
                    event_type: 2,
                    event_id: 30024,
                    ts: +new Date(),
                    sid: '',
                },
            ]);
        }
        anchorName = anchorName === 'top-div-return' ? 'top-div' : anchorName;

        lastEventRef.current = false;
        setSelectId(anchorName);
        if ('baike-link' === anchorName) {
            setCurrentKey(key => key + 1);
            // window.open(getBaikeUrl(), '_self');
            return;
        }

        if (anchorName) {
            // 找到锚点
            let anchorElement = document.getElementById(anchorName);
            // 如果对应id的锚点存在，就跳转到锚点
            if (anchorElement) {
                lastEventRef.current = false;
                setTimeout(() => {
                    anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
                    setTimeout(() => {
                        lastEventRef.current = true;
                    }, 1000);
                }, 100);
            } else {
                if (times !== undefined && times > 5) {
                    return;
                }
                setTimeout(() => {
                    scrollToAnchor({
                        anchorName: anchorName,
                        times: times !== undefined ? times + 1 : 0,
                    });
                }, 300);
            }
        }
    };
    useEffect(() => {
        if (window.location.hash && window.location.hash.indexOf('download') > -1) {
            scrollToAnchor('download');
        }
    }, []);
    useLayoutEffect(() => {
        const isEN = i18n.language === 'en';
        const divIds = isEN
            ? ['top-div', 'home', 'aum', 'tech', 'about', 'partners', 'contact']
            : ['top-div', 'home', 'business', 'about', 'team', 'contact']; // 目标div的id数组
        let divRects = divIds.map(id => {
            const div = document.getElementById(id);
            return div ? div.getBoundingClientRect() : null;
        }); // 目标div的位置信息数组
        setTimeout(() => {
            divRects = divIds.map(id => {
                const div = document.getElementById(id);
                return div ? div.getBoundingClientRect() : null;
            });
        }, 0);
        const handleScroll = event => {
            if (selectId === 'baike-link' || selectId === 'download') {
                return;
            }
            // debugger
            if (lastEventRef.current) {
                let centeredDivIndex = 0;
                let minDistanceFromCenter = Number.MAX_SAFE_INTEGER;

                const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                const windowHeight = window.innerHeight;

                for (let i = 0; i < divRects.length; i++) {
                    const divRect = divRects[i];
                    const divCenter = divRect.top + divRect.height / 2;
                    const distanceFromCenter = Math.abs(scrollTop + windowHeight / 2 - divCenter);
                    if (distanceFromCenter < minDistanceFromCenter) {
                        minDistanceFromCenter = distanceFromCenter;
                        centeredDivIndex = i;
                        setSelectId(divIds[i]); // 输出目标div的id
                    }
                    // const rect = divRects[i];
                    // if (rect && rect.top <= scrollTop + windowHeight && rect.top + 2 >= scrollTop) {
                    //     setSelectId(divIds[i]); // 输出目标div的id
                    //     break;
                    // }
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [selectId, i18n.language]);

    useEffect(() => {
        const handleOnScroll = () => {
            setShowReturn(window.scrollY > window.screen.availHeight);
            setNavIsTop(window.scrollY > 60);
        };
        window.addEventListener('scroll', handleOnScroll, true);
        return () => {
            window.removeEventListener('scroll', handleOnScroll);
        };
    }, []);
    const changeLanguage = useCallback(
        lang => {
            misc.eventReport([
                {
                    event_type: 2,
                    event_id: 30020,
                    params: { current: lang },
                    ts: +new Date(),
                    sid: '',
                },
            ]);
            i18n.changeLanguage(lang);
            window.localStorage.setItem('lk_lang', lang);
        },
        [i18n],
    );

    const Content = useMemo(() => {
        const isEN = i18n.language === 'en';
        if (selectId === 'download') {
            return (
                <div className="download">
                    <DownloadPC />
                </div>
            );
        } else if (selectId !== 'baike-link') {
            return (
                <>
                    <div className="carousel" id="home">
                        <video
                            muted
                            aria-hidden="true"
                            src="https://media.ilookcap.com/statics/offcial_website/offical.mp4"
                            autoPlay
                            loop
                            playsInline
                            className="banner-video"
                        ></video>
                        <div className="carousel-body" key={1}>
                            {i18n.language === 'zh' && (
                                <img src={header_title} className={`title`} />
                            )}
                            {i18n.language === 'en' && (
                                <img src={header_title_en_mini} className={`title title-en`} />
                            )}
                            <div className="desc">{t('banner-sub')}</div>
                        </div>
                    </div>
                    {isZH && <Advantage></Advantage>}
                    {isZH && <Support></Support>}
                    {isEN && <Aum />}
                    {isEN && <Technology />}
                    {/* <Recommend></Recommend> */}
                    <About></About>
                    {!isEN && <TeamLeader></TeamLeader>}
                    <Bank></Bank>
                </>
            );
        }
        return <></>;
    }, [selectId, t, i18n]);

    return (
        <div
            className={`page ${widthType === 1024 ? 'page1024' : ''} ${
                i18n.language === 'en' ? 'pageEn' : ''
            }`}
        >
            <img
                src={lookLogo}
                className={'ilookcap_logo'}
                alt="鹿可财富 logo"
                style={{ display: 'none' }}
            />
            <img
                src={largeLooklogo}
                className={'ilookcap_logo'}
                alt="鹿可财富 logo"
                style={{ display: 'none' }}
            />

            <div className="top-anchor" id="download" />
            <div className="top-anchor" id="top-div" />
            <div className={`nav ${selectId === 'team' || navIsTop ? 'nav-top' : ''}`}>
                <div>
                    <div
                        className="logo"
                        onClick={scrollToAnchor.bind(this, 'top-div-img')}
                        style={{ cursor: 'pointer' }}
                    >
                        <img src={LogoHeader} className="img" />
                    </div>
                    <div className="menu">
                        <a
                            className={`menu-item ${
                                selectId === 'home' ? 'menu-item-selected' : ''
                            }`}
                            onClick={scrollToAnchor.bind(this, 'home')}
                        >
                            {t('nav-Home')}
                        </a>
                        {isEN && (
                            <a
                                className={`menu-item ${
                                    selectId === 'aum' ? 'menu-item-selected' : ''
                                }`}
                                onClick={scrollToAnchor.bind(this, 'aum')}
                            >
                                {t('nav-aum')}
                            </a>
                        )}
                        {isZH && (
                            <a
                                className={`menu-item ${
                                    selectId === 'business' ? 'menu-item-selected' : ''
                                }`}
                                onClick={scrollToAnchor.bind(this, 'business')}
                            >
                                {t('nav-Services')}
                            </a>
                        )}
                        {isEN && (
                            <a
                                className={`menu-item ${
                                    selectId === 'tech' ? 'menu-item-selected' : ''
                                }`}
                                onClick={scrollToAnchor.bind(this, 'tech')}
                            >
                                {t('nav-tech')}
                            </a>
                        )}
                        <a
                            className={`menu-item ${
                                selectId === 'about' ? 'menu-item-selected' : ''
                            }`}
                            onClick={scrollToAnchor.bind(this, 'about')}
                        >
                            {t('nav-About')}
                        </a>
                        {isZH && (
                            <a
                                className={`menu-item ${
                                    selectId === 'team' ? 'menu-item-selected' : ''
                                }`}
                                onClick={scrollToAnchor.bind(this, 'team')}
                            >
                                {t('nav-Team')}
                            </a>
                        )}
                        {isEN && (
                            <a
                                className={`menu-item ${
                                    selectId === 'partners' ? 'menu-item-selected' : ''
                                }`}
                                onClick={scrollToAnchor.bind(this, 'partners')}
                            >
                                {t('nav-partners')}
                            </a>
                        )}
                        <a
                            className={`menu-item ${
                                selectId === 'contact' ? 'menu-item-selected' : ''
                            }`}
                            onClick={scrollToAnchor.bind(this, 'contact')}
                        >
                            {t('nav-Contact')}
                        </a>
                        <a
                            className={`menu-item ${
                                selectId === 'baike-link' ? 'menu-item-selected' : ''
                            }`}
                            onClick={scrollToAnchor.bind(this, 'baike-link')}
                        >
                            {t('nav-Insights')}
                        </a>
                        <a
                            className={`menu-item ${
                                selectId === 'download' ? 'menu-item-selected' : ''
                            }`}
                            onClick={scrollToAnchor.bind(this, 'download')}
                        >
                            {t('nav-Download')}
                        </a>
                        <div className="lang-content">
                            <div className="btn" onClick={() => changeLanguage('zh')}>
                                中文
                            </div>
                            <div className="line"></div>
                            <div className="btn" onClick={() => changeLanguage('en')}>
                                EN
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='nav-trading'>
                    <TickerTape symbols={symbols} displayMode='regular' colorTheme="light" className='trading-view' isTransparent={navIsTop ? false : true} ></TickerTape>
                </div> */}
            </div>

            {Content}
            {(selectId === 'baike-link' || hasLoadBaike.current) && (
                <iframe
                    id="iframe0"
                    // key={`iframekey${currentKey}`}
                    scrolling="auto"
                    src={`${getBaikeUrl()}?hidenav=true#/`}
                    width={'100%'}
                    // height={`${availHeight}px`}
                    height={'100%'}
                    onLoad={e => {
                        lastEventRef.current = true;
                        hasLoadBaike.current = true;
                    }}
                    style={{
                        marginTop: widthType === 1024 ? '84px' : '80px',
                        borderWidth: '0',
                        ...(selectId === 'baike-link' ? {} : { display: 'none' }),
                    }}
                ></iframe>
            )}

            {selectId !== 'baike-link' && (
                <>
                    <Assistant
                        returnTopCall={() => {
                            scrollToAnchor('top-div-return');
                        }}
                        showReturn={showReturn}
                    ></Assistant>
                    {selectId !== 'download' && (
                        <>
                            <Contact></Contact>

                            <div className="copyright" id="contact">
                                <div>
                                    <div className="left">
                                        <div className="icon"></div>
                                        <div className="title">
                                            2024 LOOK CAPITAL © Copyright 北京路刻科技有限公司
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => {
                                            window.open('https://beian.miit.gov.cn', '__blank');
                                        }}
                                        className="right"
                                    >
                                        京ICP备2021012808号-1
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
}
